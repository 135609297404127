import { fromEvent } from 'rxjs'

import { AvgConsentLevel } from '@app-enums'
import { getConsentLevel, setConsentLevel } from './storage.lib'

(() => {
    const cookieModal = document.getElementById('cookie-modal')
    const acceptButton = document.getElementById('cookie-modal--accept')

    if (! getConsentLevel()) {
        cookieModal?.classList.remove('hidden')
    }

    if (acceptButton) {
        fromEvent(acceptButton, 'click').subscribe(() => {
            setConsentLevel(AvgConsentLevel.MARKETING)
            cookieModal?.classList.add('hidden')
        })
    }
})()
